
import { defineComponent, onMounted, ref } from 'vue'
import OrderPill from '../components/OrderPill.vue'
import OrderPillSkeleton from '../components/OrderPillSkeleton.vue'
import SidemenuLayout from '../layouts/SidemenuLayout.vue'
import fUI from '../integration/foodexUI'
import fAPI from '../integration/foodexAPI'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'Dashboard',
    components: {
        OrderPill,
        OrderPillSkeleton,
        SidemenuLayout,
    },
    setup() {
        const latestOrders = ref([])
        const loading = ref(true)

        const loadLatest = () => {
            loading.value = true
            fAPI.getLatestOrders().then((response)=>{
                console.log('getting this back', response.data)
                fUI.toasty(response.data.orders.length + ' orders loaded')
                latestOrders.value = response.data.orders
            })
            .catch((authError: AxiosError) => {
                if (authError && authError.response)
                {
                    console.log('Operation failed', authError.response.status)
                    console.log('Error', authError.response)
                    fUI.toasty('Cannot load orders from server. Please try again')
                }
            })
            .finally(() => {
                loading.value = false
            })
        }

        onMounted(loadLatest)
        
        return {
            loading,
            latestOrders,
            loadLatest,
        }
    },
})
