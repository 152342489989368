<template>
    <sidemenu-layout title="Latest Order">
        <section class="container max-w-4xl mx-auto p-4 sm:p-12 md:p-20 h-full">
            <header class="hidden lg:flex justify-between items-center space-x-5 mb-10">
                <h1 class="text-2xl lg:text-4xl font-display font-bold">Latest Orders</h1>
                <div class="flex-none">
                    <button :disabled="loading && 'true'" class="btn btn-primary" type="button" @click="loadLatest()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                    </button>
                </div>
            </header>

            <div v-if="loading" class="space-y-4 pt-4 pb-16">
                <order-pill-skeleton></order-pill-skeleton>
                <order-pill-skeleton></order-pill-skeleton>
                <order-pill-skeleton></order-pill-skeleton>
                <order-pill-skeleton></order-pill-skeleton>
                <order-pill-skeleton></order-pill-skeleton>
                <order-pill-skeleton></order-pill-skeleton>
                <order-pill-skeleton></order-pill-skeleton>
                <order-pill-skeleton></order-pill-skeleton>
            </div>
            <div v-else class="space-y-4 pt-4 pb-16">
                <order-pill v-for="o in latestOrders" :key="o.orderID" :record="o"></order-pill>
            </div>
            
        </section>
    </sidemenu-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import OrderPill from '../components/OrderPill.vue'
import OrderPillSkeleton from '../components/OrderPillSkeleton.vue'
import SidemenuLayout from '../layouts/SidemenuLayout.vue'
import fUI from '../integration/foodexUI'
import fAPI from '../integration/foodexAPI'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'Dashboard',
    components: {
        OrderPill,
        OrderPillSkeleton,
        SidemenuLayout,
    },
    setup() {
        const latestOrders = ref([])
        const loading = ref(true)

        const loadLatest = () => {
            loading.value = true
            fAPI.getLatestOrders().then((response)=>{
                console.log('getting this back', response.data)
                fUI.toasty(response.data.orders.length + ' orders loaded')
                latestOrders.value = response.data.orders
            })
            .catch((authError: AxiosError) => {
                if (authError && authError.response)
                {
                    console.log('Operation failed', authError.response.status)
                    console.log('Error', authError.response)
                    fUI.toasty('Cannot load orders from server. Please try again')
                }
            })
            .finally(() => {
                loading.value = false
            })
        }

        onMounted(loadLatest)
        
        return {
            loading,
            latestOrders,
            loadLatest,
        }
    },
})
</script>